import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import hasClass from "has-class";
import back from "../util/back";
import { resetMetadata } from "../actions/metas";
import _ from "lodash";

class ModalWrapper extends Component {
  constructor(props, b) {
    super(props, b);

    this.state = {};

    this.onBGClick = this.onBGClick.bind(this);
  }
  componentWillMount() {
    resetMetadata();
  }
  componentDidMount() {
    const { params, itinerary, location } = this.props;
    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      let itineraryKey = Object.keys(itinerary);
      //set an itinerary by default
      if (!searchParams.has("tourid")) {
        this.clickRelated(`performance-log/tour?tourid=${itineraryKey[0]}`);
      }
    }
  }

  clickRelated(path) {
    this.props.history.push({
      pathname: path,
      state: { modal: true, prevLocation: this.props.location }, // Pass prevLocation
    });
  }

  onBGClick(e) {
    let el = e.target;
    if (
      el === this.refs.background ||
      hasClass(el, "album-modal-contents-center") ||
      hasClass(el, "album-modal-contents-center-inner") ||
      hasClass(el, "background-mousefix-left") ||
      hasClass(el, "background-mousefix-right")
    ) {
      e.preventDefault();
      e.stopPropagation();
      back(this.props.history);
    }
  }

  renderContent() {
    return (
      <div
        ref="background"
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        id="generic"
        className="album-modal"
        onClick={this.onBGClick}
      >
        {this.props.children}
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = function (state) {
  return { itinerary: state.tour.byContentfulId };
};

export default withRouter(connect(mapStateToProps)(ModalWrapper));
