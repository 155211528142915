import React, { Component } from "react";
import { connect } from "react-redux";
import marked from "marked";
import PropTypes from "prop-types";
import cn from "classnames";
import ScrollBars from "react-scrollbar";
import back from "../util/back";
import { PERFORMANCE_LOG_BASE_URL } from "../util/contants/url_constants";
import moment from "moment";
import { withRouter } from "react-router-dom";
import VerticalCenter from "../components/vertical-center";
import PhotoModal from "../components/photo-modal";
import SplashScreen from "../splash-screen";
import ItineraryHeader from "./itinerary-header";
import PerformanceTable from "./performances";
import TrackListing from "./track-wrapper";
import VideoTable from "./videos";
import GalleryTable from "./gallery-table";
import UserRequest from "./user-request";
import PlaylistManager from "../components/new-player/playlist-manager";
import Mp3Audio from "../components/new-player/mp3-player/mp3-audio";
import { canViewExclusiveContent, hasClassic } from "../user";
import { hasAuth } from "../services/api";
import { playerState } from "../actions/tracks";

class ItineraryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itinerarySelected: [],
      performanceSelected: [],
      requestsSelected: [],
      performaceLog: false,
      showRequest: false,
      mediaTag: null,
      tourId: null,
      requestId: null,
      prevMedia: false,
      selectedPhoto: null,
    };

    this.hideRequests = this.hideRequests.bind(this);
    this.hidePerformanceLog = this.hidePerformanceLog.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.parsePerformance = this.parsePerformance.bind(this);
    this.renderItinerary = this.renderItinerary.bind(this);
    this.showPerformanceLog = this.showPerformanceLog.bind(this);
    this.showRequests = this.showRequests.bind(this);
    this.showPerformanceRequests = this.showPerformanceRequests.bind(this);
    this.setUserRequestStatus = this.setUserRequestStatus.bind(this);
    this.updateWindowRoute = this.updateWindowRoute.bind(this);
    this.createPath = this.createPath.bind(this);
    this.onViewPhoto = this.onViewPhoto.bind(this);
    this.onDismissPhotoModal = this.onDismissPhotoModal.bind(this);
    this.onTrackElementClick = this.onTrackElementClick.bind(this);
    this.queryRequest = this.queryRequest.bind(this);
    this.showSubWindow = this.showSubWindow.bind(this);
  }
  componentWillMount() {
    const { itinerary, location } = this.props;

    let itinerarySelected = [];
    let performanceId = null;
    let tourId = null;
    let mediaTag = null;
    let requestId = null;

    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      // url query for tourid and performanceid
      // performance-log/tour?tourid=
      if (searchParams.has("tourid")) {
        itinerarySelected = itinerary[searchParams.get("tourid")];
        tourId = searchParams.get("tourid");
      }
      //performance-log/tour?tourid= # &performanceid=#
      if (searchParams.has("performanceid")) {
        performanceId = searchParams.get("performanceid");
      }
      //for overlaying modals
      if (searchParams.has("media")) {
        mediaTag = searchParams.get("media");
      }
      //keep open request modal
      if (searchParams.has("requestid")) {
        requestId = searchParams.get("requestid");
      }
    }
    this.setState({ itinerarySelected, tourId, mediaTag }, () => {
      if (
        (itinerarySelected &&
          itinerarySelected.performances &&
          performanceId) ||
        (itinerarySelected &&
          itinerarySelected.performances &&
          performanceId &&
          mediaTag)
      ) {
        this.showPerformanceLog({
          performanceId,
          performanceLog: true,
          changeRequestVenue: true,
          requestId,
        });
      } else if (
        itinerarySelected &&
        itinerarySelected.performances &&
        requestId &&
        !mediaTag
      ) {
        this.showRequests({ performanceId: requestId, viewedRequest: true });
      }
    });
  }

  onCloseClick() {
    back(this.props.history);
  }
  hideRequests() {
    const { mediaTag: query } = this.state;
    //update url
    let url = this.createPath({ query });
    this.updateWindowRoute(url);

    this.setState({ showRequest: false, requestId: null });
  }
  hidePerformanceLog() {
    const { tourId } = this.state;
    let url = `${PERFORMANCE_LOG_BASE_URL}${tourId}`;

    this.updateWindowRoute(url);
    this.setState({
      performaceLog: false,
      performanceSelected: [],
      mediaTag: null,
      prevMedia: false,
    });
  }
  showRequests(request) {
    const {
      performanceId,
      requestsSelected = false,
      performanceLog = false,
      viewedRequest = false,
    } = request;
    if (!requestsSelected || requestsSelected.length === 0) {
      return;
    }
    const {
      itinerarySelected: { performances },
    } = this.state;
    let requestSelected = requestsSelected || [];
    let performance = this.parsePerformance({
      performanceId,
      performances,
      performanceLog,
      viewedRequest,
      requestId: performanceId,
    });

    if (!requestsSelected && performance && performance.requestsSelected)
      requestSelected = performance.requestsSelected.userRequests;
    this.updateWindowRoute(
      `${PERFORMANCE_LOG_BASE_URL}${this.state.itinerarySelected._id}&requestid=${performanceId}`
    );

    this.setState({
      showRequest: true,
      requestsSelected: requestSelected,
      performanceRequest: performance,
      performanceSelected: performance,
      requestId: performanceId,
    });
  }
  showPerformanceRequests(performanceData) {
    const { mediaId, requestsSelected, mediaData } = performanceData;
    if (!requestsSelected || requestsSelected.length === 0) {
      return;
    }
    const { performanceSelected, mediaTag } = this.state;
    let performance = this.setUserRequestStatus(
      mediaId,
      performanceSelected,
      mediaData
    );

    let baseUrl = this.createPath({ query: mediaTag, requestId: mediaId });

    this.updateWindowRoute(baseUrl);
    this.setState({
      showRequest: true,
      requestsSelected,
      performanceRequest: performance,
      performanceSelected: performance,
      requestId: mediaId,
      prevMedia: mediaTag,
    });
  }
  setUserRequestStatus(mediaId, performanceSelected, mediaType) {
    const { logMedia } = performanceSelected;
    const mediaTypeData = logMedia[mediaType];
    let mediaData = {};
    let index = null;

    if (!_.isEmpty(mediaTypeData)) {
      mediaTypeData.forEach((media, index) => {
        if (media._id == mediaId) {
          index = index;
          media.requestViewed = true;
          mediaData = media;
        }
      });
    }
    if (performanceSelected && index && mediaType) {
      performanceSelected.logMedia[mediaType][index] = mediaData;
    }
    return performanceSelected;
  }
  parsePerformance(performance) {
    const {
      performanceId,
      performances,
      performanceLog = false,
      viewedRequest = false,
      requestId,
    } = performance;

    let performanceSelected = [];
    let index = null;
    performances.forEach((performance, _id) => {
      if (performance._id == performanceId) {
        performanceSelected = performance;
        index = _id;
      }
      if (requestId == performance._id) {
        performanceSelected.requestsSelected = performance;
      }
    });
    if (!_.isEmpty(performanceSelected)) {
      const {
        asset: {
          tracks: setList = [],
          videos = [],
          gallery = [],
          startDate: { day = "1", month = "1", year = "69" },
          venue = [],
        },
      } = performanceSelected;
      let date = new Date(`${month}/${day}/${year}`);
      date = moment(date);
      let stringDate = date.format("MMM D, YYYY");
      let name, location, country;

      if (!_.isEmpty(venue) && venue[0]) {
        name = venue[0].name;
        location = venue[0].location;
        country = venue[0].country;
      }
      performanceSelected.name = name;
      performanceSelected.location = location;
      performanceSelected.stringDate = stringDate;
      performanceSelected.country = country;

      if (performanceLog) performanceSelected.viewed = true;
      if (viewedRequest) performanceSelected.requestViewed = true;
      performanceSelected.index = index;

      performanceSelected.logMedia = { setList, videos, gallery };
      //get request related to media
      if (requestId && this.state.mediaTag) {
        performanceSelected.requestsSelected = [];
        let index = null;
        performanceSelected.logMedia[this.state.mediaTag].forEach(
          (media, index) => {
            if (media._id === requestId) {
              performanceSelected.requestsSelected = media;
              media.requestViewed = true;
              performanceSelected.logMedia[this.state.mediaTag][index] = media;
            }
          }
        );
      }
      return performanceSelected;
    }
  }
  showPerformanceLog(item) {
    const {
      itinerarySelected: { performances },
      performanceRequest,
      requestsSelected: prevRequests,
    } = this.state;
    const {
      performanceId,
      performanceLog = false,
      viewedRequest = false,
      changeRequestVenue = false,
      requestId,
    } = item;

    const performance = this.parsePerformance({
      performanceId,
      performances,
      performanceLog,
      viewedRequest,
      requestId,
    });

    let itinerary = this.state.itinerarySelected;
    let venueRequest = changeRequestVenue ? performance : performanceRequest;

    if (performance && performance.index && !_.isEmpty(performances)) {
      itinerary.performances[performance.index] = performance;
    }

    let mediaTag = [];

    let showRequest = this.state.showRequest;
    let requestsSelected = changeRequestVenue ? [] : prevRequests;
    if (this.state.mediaTag == null) {
      let logMedia = Object.keys(performance.logMedia);
      logMedia.forEach((key) => {
        let media = performance.logMedia[key];
        if (!_.isEmpty(media)) {
          //push media type if necessary
          mediaTag.push(key);
        }
      });
    }

    if (requestId && performance.requestsSelected && changeRequestVenue) {
      requestsSelected = performance.requestsSelected.userRequests;
      showRequest = true;
    }
    // set the right tag for performance log
    mediaTag = !_.isEmpty(mediaTag) ? mediaTag[0] : this.state.mediaTag;

    this.setState(
      {
        performaceLog: true,
        performanceRequest: venueRequest,
        performanceSelected: performance,
        requestsSelected,
        showRequest,
        itinerarySelected: itinerary,
        mediaTag,
        requestId,
        performanceId,
        isMp3Playing: true,
      },
      () => {
        let url = this.createPath({ requestId });
        this.updateWindowRoute(url);
      }
    );
  }

  updateWindowRoute(url) {
    window.history.replaceState({}, "", url);
  }
  redirectTo(path) {
    this.props.history.push(path);
  }
  createPath(query) {
    const { query: tag = false, requestId } = query;
    const { mediaTag, performanceId, tourId } = this.state;
    let requestTag = tag ? tag : mediaTag;
    let baseUrl = `${PERFORMANCE_LOG_BASE_URL}${tourId}`;
    if (performanceId) baseUrl += `&performanceid=${performanceId}`;
    if (requestTag) baseUrl += `&media=${requestTag}`;
    if (requestId) baseUrl += `&requestid=${requestId}`;

    return baseUrl;
  }
  changeMediaTag(mediaTag) {
    if (!canViewExclusiveContent() && mediaTag != "setList") {
      window.unlimitedAcced();
      return;
    }
    const { requestId, showRequest, prevMedia } = this.state;
    let query = showRequest && prevMedia ? prevMedia : mediaTag;
    let baseUrl = this.createPath({ query, requestId });
    this.updateWindowRoute(baseUrl);
    this.setState({ mediaTag });
  }
  logTags(logMedia) {
    let existingTags = [];
    const { mediaTag } = this.state;
    if (logMedia) {
      const logMediaKeys = Object.keys(logMedia);
      logMediaKeys.map((key) => {
        let media = logMedia[key];
        if (!_.isEmpty(media)) {
          let tagClass = cn("media-tag", { active: mediaTag == key });
          existingTags.push(
            <div
              key={key}
              className={tagClass}
              onClick={() => this.changeMediaTag(key)}
            >
              {key}
            </div>
          );
        }
      });
    }
    return existingTags;
  }
  onViewPhoto(photo, collection) {
    this.setState({ ...this.state, selectedPhoto: { photo, collection } });
  }
  onDismissPhotoModal() {
    this.setState({ ...this.state, selectedPhoto: null });
  }
  scrollClass(type) {
    const { selectedMedia, extraDescription, canPlayTour } = type;
    const { mediaTag } = this.state;

    let scrollClass = "regular";

    if (mediaTag == "setList" && !extraDescription) {
      scrollClass = selectedMedia.length < 7 ? "small" : "large";
    } else if (mediaTag == "setList" && extraDescription) {
      scrollClass =
        selectedMedia.length < 7 ? "small-description" : "large-description";
    }
    if (mediaTag == "setList" && canPlayTour) {
      scrollClass += " tour";
    }
    return scrollClass;
  }
  renderMediaContent() {
    const {
      mediaTag,
      performanceSelected: {
        logMedia,
        asset: { extraDescription = false, tourMp3 = false, tourOs = false },
      },
      showRequest,
    } = this.state;
    const { userRequestItems } = this.props;
    const selectedMedia = logMedia[mediaTag];
    const canPlayTour = tourOs || tourMp3;

    const scrollClass = this.scrollClass({
      selectedMedia,
      extraDescription,
      canPlayTour,
    });
    if (mediaTag == "setList") {
      return (
        <div className="content-wrap">
          <ScrollBars key={mediaTag} className={scrollClass}>
            <TrackListing
              viewTrackOnTimeline={this.viewTrackOnTimeline}
              viewInfoCard={this.viewTrack}
              tracksMap={selectedMedia}
              tracks={selectedMedia}
              title="TITLE"
              userRequestItems={userRequestItems}
              showRequests={this.showPerformanceRequests}
              isRequestVisible={showRequest}
              context={this.props.history}
              onTrackElementClick={this.onTrackElementClick}
              canViewExclusiveContent={canViewExclusiveContent}
            />
          </ScrollBars>
          {extraDescription && (
            <div
              className="extraDescription"
              dangerouslySetInnerHTML={{
                __html: marked(extraDescription || ""),
              }}
            />
          )}
        </div>
      );
    } else if (mediaTag == "videos") {
      return (
        <div className="content-wrap">
          <ScrollBars key={mediaTag} className={scrollClass}>
            <VideoTable
              videos={selectedMedia}
              userRequestItems={userRequestItems}
              showRequests={this.showPerformanceRequests}
              context={this.props.history}
              isRequestVisible={showRequest}
              canViewExclusiveContent={canViewExclusiveContent}
            />
          </ScrollBars>
        </div>
      );
    } else if (mediaTag === "gallery") {
      return (
        <ScrollBars key={mediaTag} className={scrollClass}>
          <GalleryTable
            location={this.props.location}
            gallery={selectedMedia}
            onViewPhoto={this.onViewPhoto}
            canViewExclusiveContent={canViewExclusiveContent}
          />
        </ScrollBars>
      );
    }
  }
  showSubWindow(free) {
    return (
      (hasClassic() && !free) ||
      (!canViewExclusiveContent() && !free) ||
      (hasAuth() && !canViewExclusiveContent() && !hasClassic()) ||
      !hasAuth()
    );
  }
  onTrackElementClick(item, playerStatus) {
    const { track, disabledbyId, free } = item;
    const { id, orastream = false, trackId = false } = track;
    if (this.showSubWindow(free)) {
      window.unlimitedAcced();
      return;
    }
    const currTrack = PlaylistManager.currentTrack();
    let isOsPlaying = false;
    let isMp3Playing = false;
    if (!track) return;

    if (disabledbyId) {
      window.subs();
      return;
    }
    if (id !== _.get(currTrack, "id") && !orastream && !trackId) {
      //performance log url path and flag
      if (track && track.mp3audio) {
        const { mediaTag, requestId } = this.state;
        let url = this.createPath({ mediaTag, requestId });

        track.performanceLog = true;
        track.url = url;
        track.listOnly = false;
        isMp3Playing = true;
        playerState(true);
      }
      // pause previous song
      _OS.resetContext();
      //update current track on the playlist player
      PlaylistManager.playTrack(track);
      isOsPlaying = true;
    } else if (!orastream && id === _.get(currTrack, "id") && !trackId) {
      if (Mp3Audio.isMp3Playing()) {
        Mp3Audio.pauseMp3();
        isMp3Playing = false;
      } else {
        Mp3Audio.playMp3();
        isMp3Playing = true;
      }
    } else if (!orastream && trackId) {
      window.displayWarningMessage("missing-track");
      return;
    } else if (orastream && id !== _.get(currTrack, "id") && trackId) {
      if (Mp3Audio.isMp3Playing()) {
        Mp3Audio.pauseMp3();
        isMp3Playing = false;
      }
      PlaylistManager.playTrack(track);
      isOsPlaying = true;
    } else if (id === _.get(currTrack, "id") && orastream && trackId) {
      if (playerStatus === "LIVEPLAY") {
        PlaylistManager.pause();
        isOsPlaying = false;
      }
      if (playerStatus === "PAUSED") {
        PlaylistManager.play();
        isOsPlaying = true;
      }
    }
    this.setState({ isOsPlaying, isMp3Playing });
  }

  renderPerformanceLog() {
    const {
      performanceSelected,
      itinerarySelected,
      mediaTag,
      requestId,
      isMp3Playing,
      isOsPlaying,
    } = this.state;
    if (performanceSelected) {
      const { logMedia, name, stringDate, asset, location, country } =
        performanceSelected;
      const {
        tourMp3 = false,
        tourOs = false,
        _id,
        albumImage: playerImage,
        free,
      } = asset;
      const { performer } = itinerarySelected;
      const url = this.createPath({ mediaTag, requestId });

      let performanceTrack = [];

      //오늘도 어제도 아니 잊고 🤍
      //create performance track for main player
      if (tourMp3 || tourOs) {
        performanceTrack = {
          title: name,
          performer: (performer && performer.name) || "",
          mp3audio: tourOs ? false : tourMp3,
          orastream: tourOs,
          trackId: tourOs ? tourOs : false,
          id: _id,
          performanceLog: true,
          url,
          location,
          country,
          playerImage,
        };
      }
      return (
        <div className="performance-log-modal">
          <h1>Performance Log</h1>
          <span
            className="back-btn"
            onClick={() => this.hidePerformanceLog()}
          />
          <div className="album-modal-top">
            <ItineraryHeader
              itineratyData={itinerarySelected}
              venue={name}
              dateText={stringDate}
              tourMp3={tourMp3}
              tourOS={tourOs}
              performanceTrack={performanceTrack}
              onTrackElementClick={this.onTrackElementClick}
              mediaTag={mediaTag}
              free={free}
              venueLocation={location}
              venueCountry={country}
              isMp3Playing={isMp3Playing}
              isOsPlaying={isOsPlaying}
            />
          </div>
          <div className="media-tag-wrapper"> {this.logTags(logMedia)}</div>
          <div className="content">{this.renderMediaContent()}</div>
        </div>
      );
    } else {
      return <SplashScreen loadState={100} />;
    }
  }
  queryRequest() {
    if (!canViewExclusiveContent() || !hasAuth()) {
      window.unlimitedAcced();
      return;
    }
    this.redirectTo("/request-modal");
  }
  renderItinerary() {
    const { itinerarySelected, showRequest } = this.state;
    const { userRequestItems } = this.props;
    if (itinerarySelected) {
      return (
        <div className="itinerary-log-modal">
          <h1>Itinerary</h1>
          <div className="album-modal-top">
            <ItineraryHeader
              itineratyData={itinerarySelected}
              dateText={itinerarySelected.dateText}
            />
          </div>
          <div id="perf-table-header">
            <div className="perf-nav-date">Date</div>
            <div className="perf-nav-venue">Venue</div>
            <div className="perf-nav-location">Location</div>
            <div className="perf-nav-log">Log</div>
            <div className="perf-nav-userRequest">
              User
              <br />
              Requests*
            </div>
          </div>
          <ScrollBars key="performance">
            <PerformanceTable
              performanceData={itinerarySelected}
              showPerformanceLog={this.showPerformanceLog}
              userRequestItems={userRequestItems}
              showRequests={this.showRequests}
              isRequestVisible={showRequest}
            />
          </ScrollBars>
        </div>
      );
    } else {
      return <SplashScreen loadState={100} />;
    }
  }
  render() {
    const {
      performaceLog,
      selectedPhoto,
      showRequest,
      requestsSelected,
      performanceRequest,
    } = this.state;
    return (
      <>
        <VerticalCenter
          className="album-modal-contents-center"
          innerClassName="album-modal-contents-center-inner"
          onClick={this.onBGClick}
        >
          <div id="performance-log" className="album-modal-contents">
            <div className="background-performance">
              {performaceLog
                ? this.renderPerformanceLog()
                : this.renderItinerary()}
              <div className="submit-request-btn" onClick={this.queryRequest}>
                submit a request
              </div>
            </div>
            <div className="close-button" onClick={this.onCloseClick} />
          </div>
          {showRequest && (
            <UserRequest
              requestsSelected={requestsSelected}
              performanceSelected={performanceRequest}
              hideRequests={this.hideRequests}
            />
          )}
        </VerticalCenter>

        {selectedPhoto && (
          <div className="perf-photo-modal-wrapper">
            <PhotoModal
              photo={selectedPhoto.photo}
              location={this.props.location}
              collection={selectedPhoto.collection}
              darkBackground={true}
              onDismiss={this.onDismissPhotoModal}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    itinerary: state.tour.byContentfulId,
    userRequestItems: state.userRequestParams.all,
  };
};

export default withRouter(connect(mapStateToProps)(ItineraryModal));
