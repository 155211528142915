import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import hasClass from "has-class";
import back from "../util/back";
import { resetMetadata } from "../actions/metas";
import VerticalCenter from "../components/vertical-center";
import _ from "lodash";
import SplashScreen from "../splash-screen";
import { Formik, Form, Field } from "formik";
import Input from "../components/input";
import { sendEmail } from "../services/api";
import moment from "moment";
import ScrollBars from "react-scrollbar";

class RequestModal extends Component {
  constructor(props, b) {
    super(props, b);

    this.state = {
      inputDisabled: true,
      permission: null,
      state: "initial",
      confirm: false,
      successFormValues: {
        firstname: "",
        email: "",
        date: "",
        note: "",
      },
    };

    this.onBGClick = this.onBGClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.error = this.error.bind(this);
    this.initial = this.initial.bind(this);
    this.loading = this.loading.bind(this);
    this.success = this.success.bind(this);
  }
  componentWillMount() {
    resetMetadata();
  }
  componentDidMount() {}

  getContent() {
    return this[this.state.state]();
  }

  loading() {
    return (
      <div className="content loading">
        <div className="message">
          <SplashScreen loadState={100} />
        </div>
      </div>
    );
  }

  success() {
    return (
      <div className="content content--initial">
        <div className="top-message">
          Success! Your request has been submitted.
          <br />
          <br />
          <br />
          So, what happens now?
          <br />
          <br />
          -We will log and review member requests based on the order they come
          in.
          <br />
          <br />
          -If a member makes multiple requests, we will pick one and then pick a
          second after we fulfill requests made by other members.
          <br />
          <br />
          -Requests will be selected based on the date of submission, the date
          of the requested performance, the popularity of the request, and the
          availability. These factors will be considered once we've determined
          whether (and how easily) we can prepare any given show for the
          site—efficiency will be our first concern in order to get as many
          concerts up with the best audio quality as quickly as possible.
          <br />
          <br />
          -We promise to deliver amazing never before released performances to
          our RUST & PATRON members as frequently as possible, and we thank you
          for taking this trip with us.
          <br />
          <br />
        </div>
      </div>
    );
  }
  error() {
    return (
      <div className="content content--message">
        <div className="message">
          Sorry! We were unable to process your request.
          <br />
          Please try again.
        </div>
        <div className="buttons">
          <div
            className="button clear"
            onClick={() => this.setState({ state: "initial" })}
          >
            Try Again
          </div>
        </div>
      </div>
    );
  }

  allowPrint(props) {
    return (
      <div className="group " role="group" aria-labelledby="my-radio-group">
        <span className="value">Yes</span>
        <label>
          <Field type="radio" name="print" value={true} />
          <span className="checkmark"></span>
        </label>
        <span className="value">No</span>
        <label>
          <Field type="radio" name="print" value={false} />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }

  initial() {
    return (
      <Formik
        initialValues={{
          firstname: "",
          email: "",
          date: "",
          note: "",
          print: null,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          const {
            firstname: name,
            email: from,
            date,
            note,
            subject = "request",
            print,
          } = values;
          const body = `Performance Date requetested: ${date}. \n ${note} \n User agrees to share print: ${print}`;
          this.setState({ state: "loading" });
          sendEmail({
            name,
            from,
            body,
            subject,
            to: subject,
            date,
          })
            .then(() => {
              this.setState({ confirm: true, state: "success" });
            })
            .catch((err) => {
              this.setState({ state: "error" });
            });
        }}
        validate={(values, props) => {
          let errors = {};

          if (!values.firstname) {
            errors.firstname = "Required";
          } else if (values.firstname.length >= 30) {
            errors.firstname = "You have exceeded max number of characters";
          }

          if (!values.email) {
            errors.email = "Required";
          } else if (values.email.length >= 40) {
            errors.email = "You have exceeded max number of characters";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.date) {
            errors.date = "Required";
          } else if (values.date.length > 10) {
            errors.date = "You have exceeded max number of characters";
          } else if (
            !/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/i.test(
              values.date
            )
          ) {
            errors.date = "Invalid date format (mm/dd/yyyy)";
          }

          if (!values.note) {
            errors.note = "Required";
          }
          return errors;
        }}
        render={(props) => (
          <Form className="content content--initial">
            <div className="fields-wrapper">
              <div className="top-message">
                {this.state.confirm && (
                  <p>
                    SUCCESS
                    <br />
                    Your message was sent.
                    <br />
                    Thanks!
                  </p>
                )}
              </div>

              <Fragment>
                <Input
                  className="input"
                  label="Name"
                  limit={30}
                  isFormikField
                  name="firstname"
                  onClick={() => setFieldValue("print", false)}
                />
              </Fragment>

              <Fragment>
                <Input
                  className="input"
                  label="Email"
                  limit={40}
                  isFormikField
                  name="email"
                />
              </Fragment>

              <Fragment>
                <Input
                  className="input"
                  label="Date of Performance (mm/dd/yyyy)"
                  isFormikField
                  name="date"
                  disabledDays={{}}
                />
              </Fragment>

              <Fragment>
                <Input
                  className="input"
                  label="Message"
                  limit={240}
                  isFormikField
                  isTextArea
                  name="note"
                />
              </Fragment>
            </div>
            <div className="print-agreement">
              <div id="radio-group">Can we print your message?</div>
              {this.allowPrint(props)}
            </div>
            <div
              className="request-btn"
              onClick={() => {
                if (this.props.confim) return;
                props.validateForm().then((errors) => {
                  if (
                    props.values.print !== null &&
                    _.isEmpty(errors) &&
                    !this.state.confirm
                  ) {
                    props.submitForm();
                  }
                });
              }}
            >
              Submit
            </div>
          </Form>
        )}
      />
    );
  }

  onBGClick(e) {
    let el = e.target;
    if (
      el === this.refs.background ||
      hasClass(el, "album-modal-contents-center") ||
      hasClass(el, "album-modal-contents-center-inner") ||
      hasClass(el, "background-mousefix-left") ||
      hasClass(el, "background-mousefix-right")
    ) {
      e.preventDefault();
      e.stopPropagation();
      back(this.props.history);
    }
  }
  onCloseClick() {
    back(this.props.history);
  }

  render() {
    return (
      <div
        ref="background"
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        id="generic"
        className="album-modal"
        onClick={this.onBGClick}
      >
        <VerticalCenter
          className="album-modal-contents-center "
          innerClassName="album-modal-contents-center-inner"
          onClick={this.onBGClick}
        >
          <div id="performance-log" className="album-modal-contents">
            <div className="background-request">
              <ScrollBars>
                <div className="performance-log-modal">
                  <div className="header-request" />
                  {this.getContent()}
                </div>
              </ScrollBars>
            </div>
            <div className="close-button" onClick={this.onCloseClick} />
          </div>
        </VerticalCenter>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return { itinerary: state.tour.byContentfulId };
};

export default withRouter(connect(mapStateToProps)(RequestModal));
